@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  background: rgb(239, 239, 239);
  margin: 0;
  padding: 0;
  color: black;
}

.app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

body,
h1,
h2,
.task-form input,
.task-form textarea,
.task-form select,
.task-form button, 
input,
button
{  
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;}


  button{
    font-weight: 700;
  }
h1 {
  text-align: left;
  font-size: 50px;
  font-weight: 700;
}

h2{
  text-align: left;
  font-weight: 500;
}

.task-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.task-form input,
.task-form textarea,
.task-form select,
.task-form button {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 600px;
}



.task-form button {
  background: #28a745;
  color: #fff;
  cursor: pointer;
  width: 200px;
}

.task-form button:hover {
  background: #218838;
}

.task-lists {
  display: flex;
  gap: 20px;
}

.task-list {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.task-list.Immediate {
  border-left: 4px solid #ff4757;
}

.task-list.High {
  border-left: 4px solid #ffa502;
}

.task-list.Normal {
  border-left: 4px solid #2ed573;
}

.task {
  background: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-content h3 {
  margin: 0;
  color: #333;
}

.task-actions {
  display: flex;
  gap: 10px;
}

.task-actions button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.task-actions button:hover {
  opacity: 0.8;
}

.task-actions button:nth-child(1) {
  background: #007bff;
  color: #fff;
}

.task-actions button:nth-child(2) {
  background: #dc3545;
  color: #fff;
}
.email-form-container {
  margin-bottom: 20px;
}

.email-form {
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
}

.email-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 600px;
}

.email-form button {
  padding: 10px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 200px;
}

.email-form button:hover {
  background: #0056b3;
}

.suggested-emails {
  display: flex;
  gap: 10px;
}

.suggested-emails button {
  padding: 5px 10px;
  background: #3e4246;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.suggested-emails button:hover {
  background: #282a2c;
}

